import { AuthToken, DecodedToken } from '@services/auth-token/authToken';
import { getLoginUrl } from '@services/url/url';

import { persistor } from '@store/storeConfig';
import { AuthenticatedUser } from './authentication.reducer';
import { REMOVE_CURRENT_USER, SET_CURRENT_USER, SET_CURRENT_USER_SETTINGS, SET_USER_LOADING_STATE, UPDATE_CURRENT_USER } from './authentication.types';

export const updateCurrentUser = (user: AuthenticatedUser) => ({
    type: UPDATE_CURRENT_USER,
    payload: user,
});

interface CurrentUserSettings {
    hasAuthors: boolean;
    hasLanguages: boolean;
}

export const setCurrentUserSettings = ({
    hasAuthors,
    hasLanguages
}: CurrentUserSettings) => ({
    type: SET_CURRENT_USER_SETTINGS,
    payload: {
        hasAuthors,
        hasLanguages,
    }
});

export const setCurrentUser = (decoded: DecodedToken | null) => ({
    type: SET_CURRENT_USER,
    payload: {
        username: decoded?.username ?? null,
        firstname: decoded?.firstname ?? null,
        lastname: decoded?.lastname ?? null,
        avatar: decoded?.avatar ?? null,
        email: decoded?.email ?? null,
        role: decoded?.role ?? null,
        site: decoded?.site ?? null,
        allowedSites: decoded?.allowedSites ?? null,
        selectedSiteIndex: 0,
    }
});

export const removeCurrentUser = () => ({
    type: REMOVE_CURRENT_USER,
});

//Log user out
export const logoutUser = async () => {
    AuthToken.removeToken();
    removeCurrentUser();
    await persistor.purge();

    location.href=getLoginUrl(true);
};

export const setUserLoadingState = (status: boolean) => ({
    type: SET_USER_LOADING_STATE,
    payload: status
});
