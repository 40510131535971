import classNames from 'classnames';

import { SocialMediaLogos } from '@constants/socialMedia';
import { SocialMediaTypes } from '@shared/enum/socialMediaTypes';

import './SocialMediaProfileAvatarSM.scss';

interface Props {
    thumbnail: string;
    networkType: SocialMediaTypes;
    className?: string;
}

const NetworkTypeToLogoMapper = {
    [SocialMediaTypes.FACEBOOK]: SocialMediaLogos.facebook.filled,
    [SocialMediaTypes.INSTAGRAM]: SocialMediaLogos.instagram.normal,
    [SocialMediaTypes.TWITTER]: SocialMediaLogos.twitter.filled,
    [SocialMediaTypes.YOUTUBE]: SocialMediaLogos.youtube.normal,
};

const SocialMediaProfileAvatarSM = ({ className, thumbnail, networkType }: Props) => (
    <div className={classNames('smp-head-avatar', { [className]: !!className })}>
        <span>
            <img
                className="smp-head-thumb"
                src={thumbnail}
                alt={`${networkType} thumbnail`}
            />
        </span>
        <span>
            <img
                className="smp-head-network"
                src={NetworkTypeToLogoMapper?.[networkType]}
                alt={`${networkType} thumbnail`}
            />
        </span>
    </div>
);

export default SocialMediaProfileAvatarSM;
