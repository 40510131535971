import { useLayoutEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import useToast from '@components/widgets/toast-message/useToast';
import { IndepthClients } from '@constants/clients';
import { useAppSelector } from '@hooks/redux/useRedux';
import useGetUserSelectedSite from '@hooks/user/useGetUserSelectedSite';

export function getIsClientAllowedGenderTracker(client: string) {
    const allowedClients = [IndepthClients.inkyfada, IndepthClients.dev, IndepthClients._reefyemen];

    return allowedClients.includes(client);
}

/**
 *
 * Signifies that the feature is only viewed internally
 */
const GenderTrackerRoutePrivilege = () => {
    const navigate         = useNavigate();
    const selectedSite     = useGetUserSelectedSite();
    const userLoadingState = useAppSelector(state => state.authenticationReducer.isLoading);
    const { triggerToast } = useToast();

    useLayoutEffect(() => {
        if (!userLoadingState) {
            return;
        }

        const isAllowed = getIsClientAllowedGenderTracker(selectedSite?.name);

        if (!isAllowed) {
            triggerToast('Unauthorized access!', 'warn');
            navigate(-1);
        }
    }, [selectedSite, userLoadingState]);

    return <Outlet />;
};

export default GenderTrackerRoutePrivilege;
