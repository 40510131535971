import Config from 'config/config';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

import type { Site } from 'shared/interfaces/store/sites/site';

import { isEmpty } from 'lodash';
import { getLoginUrl } from 'services/url/url';

export interface DecodedToken {
    readonly username: string;
    readonly firstname: string;
    readonly lastname: string;
    readonly avatar: string;
    readonly email: string;
    readonly role: string;
    site: Site;
    readonly allowedSites: Site[];
    readonly iat?: number;
}

export function instanceOfDecodedToken(object: any): object is DecodedToken {
    return 'username' in object && 'site' in object;
}

export const TOKEN_STORAGE_KEY = 'IA_AT';
export const DASH_ENV_STORAGE_KEY = 'IA_DE';

const { app } = Config;

export class AuthToken {
    readonly decodedToken: DecodedToken = null;

    constructor(readonly token?: string) {
        // // we are going to default to an expired decodedToken
        // this.decodedToken = { username: '', site: {} as Site, iat: 0 };

        // then try and decode the jwt using jwt-decode
        try {
            if (!token) {
                location.href = getLoginUrl();
            }

            this.decodedToken = jwtDecode(token);

            if (!this.isValid){
                location.href = getLoginUrl();
            }
        } catch (e) {
            location.href = getLoginUrl();
        }
    }

    static removeToken() {
        // remove token on this site
        Cookies.remove(TOKEN_STORAGE_KEY);
        Cookies.remove(DASH_ENV_STORAGE_KEY);

        Cookies.remove(TOKEN_STORAGE_KEY, { domain: window.location.hostname });
        Cookies.remove(DASH_ENV_STORAGE_KEY, { domain: window.location.hostname });

        Cookies.remove(DASH_ENV_STORAGE_KEY, {
            domain: app.domain,
        });

        // remove token on subdomain
        return Cookies.remove(TOKEN_STORAGE_KEY, {
            domain: app.domain,
        });
    }

    static getDashboardEnvironment() {
        const env = Cookies.get(DASH_ENV_STORAGE_KEY) ?? 'dev';

        return env === 'local' ? 'dev' : env;
    }

    static getToken(): string {
        return Cookies.get(TOKEN_STORAGE_KEY);
    }

    get getDecodedToken() {
        return this.decodedToken;
    }

    get authorizationString() {
        return `Bearer ${String(this.token)}`;
    }

    // get expiresAt(): Date {
    //     return new Date(this.decodedToken.exp * 1000);
    // }

    // get isExpired(): boolean {
    //     return new Date() > this.expiresAt;
    // }

    get isValid(): boolean {
        return !isEmpty(this.decodedToken) &&
                instanceOfDecodedToken(this.decodedToken) &&
                !isEmpty(this.decodedToken.username) &&
                !isEmpty(this.decodedToken.site);
    }
}
